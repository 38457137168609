<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div v-sticky class="vd_edhea vg_button_group" style="padding-top: 16px; background: white">
      <editHeader :isShow="isShow" :btn="btn" :strForm="sconForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('sconFormRef')" />
      <div v-if="isShow" class="vd_export" style="padding-top: 16px">
        <el-button class="vg_mb_8" type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
        <el-button class="vg_mb_8" size="small" type="warning" @click="getSconInfo(1)" v-if="sconForm.status === 2">生成成品采购合同 </el-button>
        <el-button class="vg_mb_8" size="small" type="warning" @click="getSconInfo(2)" v-if="sconForm.status === 2">生成材料采购合同 </el-button>
        <el-button class="vg_mb_8" size="small" type="warning" @click="generateSconChangeForm" v-if="sconForm.status === 2">生成销售合同变更单 </el-button>
        <el-button class="vg_mb_8" size="small" type="success" @click="collectionContractVisible = true" v-if="sconForm.status === 2 && isBussinessDept5"
          >同步Qarma
        </el-button>
        <el-popover v-if="sconForm.scon_type === 1" placement="right" trigger="hover" width="500">
          <el-button class="vg_mb_8" size="small" icon="el-icon-printer" type="info" @click="clickPrint(1)">打印 </el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(1)">下载PDF </el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExportExcel(1)"> 下载Excel </el-button>
          <br />
          <el-button class="vg_mb_8" size="small" icon="el-icon-printer" type="info" @click="clickPrint(2)">打印版本二 </el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(2)"> 下载版本二PDF </el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExportExcel(2)">下载版本二Excel </el-button>
          <br />
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(3)">下载版本二PDF含猫草 </el-button>
          <span class="vg_ml_8">是否提现唛头：</span>
          <el-radio v-model="cust_shipmark_type" label="1">是</el-radio>
          <el-radio v-model="cust_shipmark_type" label="2">否</el-radio>
          <br />
          <el-button slot="reference" class="vg_ml_8" type="primary" size="small">其他操作</el-button>
        </el-popover>
        <el-popover v-if="sconForm.scon_type === 2" placement="right" trigger="hover" width="500">
          <el-button class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="clickPrint(1)">打印中文合同</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(1)">下载PDF</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExportExcel(1)"> 下载Excel</el-button>
          <br />
          <el-button class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="clickPrint(2)">打印Invoice</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(2)">下载PDF</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExportExcel(2)"> 下载Excel</el-button>
          <br />
          <el-button class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="clickPrint(3)">打印装箱单</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(3)">下载PDF</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExportExcel(3)"> 下载Excel</el-button>
          <br />
          <el-button class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="clickPrint(4)">打印英文合同</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(4)">下载PDF</el-button>
          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExportExcel(4)"> 下载Excel</el-button>
          <br />
          <!--          <el-button class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="clickPrint(5)">打印开票明细</el-button>-->
          <!--          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExport(5)">下载PDF</el-button>-->
          <!--          <el-button class="vg_mb_8" icon="el-icon-bottom" size="small" type="primary" @click="clickExportExcel(5)"> 下载Excel</el-button>-->
          <!--          <br />-->
          <div style="display: flex; align-items: flex-start">
            <!--            <el-upload-->
            <!--              ref="upload"-->
            <!--                          :action="helper.modePrepend(docuAPI.uploadInv)"-->
            <!--              :before-upload="uploadInvoicingDetails"-->
            <!--              :data="{ scon_id: sconForm.scon_id }"-->
            <!--              :show-file-list="false"-->
            <!--              class="vg_mb_8 upload-demo"-->
            <!--            >-->
            <!--              <el-button slot="trigger" size="small" type="success">上传开票明细</el-button>-->
            <!--              <div slot="tip" class="el-upload__tip">文件大小不得超过200MB</div>-->
            <!--            </el-upload>-->
            <!--            <el-select-->
            <!--              v-model="sconForm.financialNotifier"-->
            <!--              class="vg_mr_8 vg_mb_8"-->
            <!--              clearable-->
            <!--              filterable-->
            <!--              placeholder="请选择财务通知人"-->
            <!--              size="small"-->
            <!--              style="width: 160px"-->
            <!--            >-->
            <!--              <el-option v-for="item in financeList" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
            <!--            </el-select>-->
            <!--            <el-button class="vg_mb_8" size="small" type="success" @click="notifyFinance">通知财务</el-button>-->
            <el-button class="vg_mb_8" size="small" type="success" @click="addDomeSconRequisition" v-if="sconForm.status === 2">生成内贸开票通知</el-button>
          </div>
          <el-button slot="reference" class="vg_ml_8" size="small" type="primary">其他操作</el-button>
        </el-popover>
      </div>
      <div class="vd_export2" style="padding-top: 16px">
        <span style="margin-right: 20px; color: red; font-size: 20px">第{{ sconForm.num }}次修改</span>
        <el-button type="text" @click="recordDialogVisible = true">变更记录</el-button>
        <el-dialog :visible.sync="recordDialogVisible" append-to-body title="变更记录" width="70%" @opened="getRecordData">
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in recordList"
              :key="item.podc_record"
              :title="getDate(item.create_time, false) + '销售合同' + item.scon_no + '由' + item.stff_name + '进行了第' + item.num + '次变更'"
              :name="item.podc_record"
            >
              <el-collapse>
                <el-collapse-item title="主内容">
                  <span v-html="item.changes_text"></span>
                </el-collapse-item>
                <el-collapse-item title="成品信息">
                  <span v-for="prod in item.sconChangProdList" v-html="prod.scon_changkey + prod.scon_changvalue"></span>
                </el-collapse-item>
                <el-collapse-item title="部件信息">
                  <span v-for="part in item.sconChangPartList" v-html="part.scon_changkey + part.scon_changvalue"></span>
                </el-collapse-item>
                <el-collapse-item title="材料信息">
                  <span v-for="mtrb in item.sconChangMtrbList" v-html="mtrb.scon_changkey + mtrb.scon_changvalue"></span>
                </el-collapse-item>
                <el-collapse-item title="费用信息">
                  <span v-for="fees in item.sconChangFeesList" v-html="fees.scon_changkey + fees.scon_changvalue"></span>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-dialog>
        <span style="margin-right: 2px">{{ sconForm.scon_type === 1 ? '外销' : '内贸' }}合同号:</span>
        <el-input size="small" style="width: 150px" disabled v-model="sconForm.scon_no" maxlength="8" show-word-limit></el-input>
        <el-input
          :disabled="isShow"
          size="small"
          style="width: 150px"
          v-model="sconForm.scon_no_remark"
          @input="sconForm.scon_no_remark = helper.keepEngNum(sconForm.scon_no_remark)"
          show-word-limit
        >
        </el-input>
      </div>
    </div>
    <el-form :model="sconForm" size="mini" ref="sconFormRef" :rules="sconFormRules" label-width="120px" :disabled="isShow">
      <el-row>
        <el-col :md="24" class="vg_mb_8">
          <el-col :md="8">
            <el-form-item label="销售类型" prop="scon_type">
              <el-radio v-model="sconForm.scon_type" :label="1" @input="val => changeCurrency('USD')">外销</el-radio>
              <el-radio v-model="sconForm.scon_type" :label="2" @input="val => changeCurrency('RMB')">内贸</el-radio>
            </el-form-item>
          </el-col>
          <!--          <el-col :md="8">
            <el-form-item label="客户全称" prop="cust_dept_id">
              <el-select v-model="sconForm.cust_name" filterable @change="custDeptChange">
                <el-option v-for="item in []" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>-->
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_id">
            <el-select v-model="sconForm.cust_id" clearable size="small" filterable placeholder="请选择客户" @change="val => clickSelectCust(val, 1)">
              <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
            </el-select>
            <!--            <el-input v-model="sconForm.cust_abbr" disabled placeholder="请选择客户">-->
            <!--              <template slot="append">-->
            <!--                <el-link :disabled="isShow" class="vg_pointer" @click="clickSelectCust(1)" type="primary">双击选择 </el-link>-->
            <!--              </template>-->
            <!--            </el-input>-->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="最终客户" prop="cust_babbr">
            <el-select v-model="sconForm.cust_bid" size="small" filterable placeholder="请选择最终客户" @change="val => clickSelectCust(val, 2)">
              <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
            </el-select>
            <!--            <el-input v-model="sconForm.cust_babbr" placeholder="请选择最总客户" >-->
            <!--              <template slot="append">-->
            <!--                <el-link :disabled="isShow" class="vg_pointer" @click="clickSelectCust(2)" type="primary">双击选择 </el-link>-->
            <!--              </template>-->
            <!--            </el-input>-->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户订单号" prop="scon_cust_no">
            <el-input
              v-model="sconForm.scon_cust_no"
              placeholder="请输入客户订单号"
              @blur="val => changeCustNoIsRepeat(val)"
              maxlength="20"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="合同日期" prop="scon_cndate">
            <el-date-picker v-model="sconForm.scon_cndate" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售所属部门" prop="cust_dept_id">
            <el-select v-model="sconForm.cust_dept_id" filterable @change="custDeptChange">
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户订单号备注" prop="scon_cust_no_remark">
            <el-input v-model="sconForm.scon_cust_no_remark" placeholder="请输入客户订单号备注" maxlength="20" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="贸易方式" prop="cust_tradeway">
            <el-select v-model="sconForm.cust_tradeway" placeholder="请选择贸易方式">
              <el-option v-for="item in tradewayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="装运日期" prop="scon_shdate">
            <el-date-picker value-format="timestamp" v-model="sconForm.scon_shdate" @change="val => chageShdate(val)" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item :label="`${sconForm.scon_type === 1 ? '外销' : '销售'}经办人`" prop="cust_stff_id">
            <el-select v-model="sconForm.cust_stff_id" :disabled="!sconForm.cust_dept_id" filterable placeholder="请选择" @change="changeCustStff">
              <el-option v-for="item in stffUserList" :key="item.stff_id + item.stff_name" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="付款方式" prop="cust_payway">
            <el-select v-model="sconForm.cust_payway" @change="val => updatePpdate(val)" placeholder="请选择付款方式">
              <el-option v-for="item in custPaywayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户账期" prop="cust_creday">
            <el-input
              v-model="sconForm.cust_creday"
              placeholder="请输入客户账期"
              maxlength="3"
              show-word-limit
              @input="sconForm.cust_creday = helper.pureNumber(sconForm.cust_creday)"
              @change="val => (sconForm.scon_ppdate = sconForm.scon_shdate + val * 60 * 60 * 24 * 1000)"
            >
              <!--@change="shdateChange2"-->
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="预计收款日期" prop="scon_ppdate">
            <el-date-picker v-model="sconForm.scon_ppdate" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 1">
        <el-col :md="8">
          <el-form-item label="起运港" prop="scon_sport">
            <el-select v-model="sconForm.scon_sport" placeholder="请选择起运港" filterable>
              <el-option v-for="item in portOfShipmentList" :key="item.value" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的国" prop="cust_dcountry">
            <el-select v-model="sconForm.cust_dcountry" placeholder="请选择目的国" filterable>
              <el-option v-for="item in custCountryList" :key="item.id" :label="item.param2" :value="item.param2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的港" prop="cust_dport">
            <el-select v-model="sconForm.cust_dport" filterable placeholder="请选择目的港">
              <el-option v-for="item in custDportList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="货款金额" prop="scon_prtotal">
            <el-input v-model="sconForm.scon_prtotal" disabled maxlength="13" placeholder="暂无货款金额">
              <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="费用金额" prop="scon_fetotal">
            <el-input v-model="sconForm.scon_fetotal" disabled maxlength="13" placeholder="暂无费用金额">
              <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="汇率" prop="scon_usdrate">
            <el-input
              :disabled="this.rateDisable"
              v-model="sconForm.scon_usdrate"
              placeholder="请输入汇率"
              maxlength="9"
              show-word-limit
              @input="sconForm.scon_usdrate = helper.keepTNum1(sconForm.scon_usdrate)"
              @change="sconForm.scon_usdrate = new BigNumber(sconForm.scon_usdrate).toFixed(6)"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="合同金额" prop="scon_total">
            <el-input v-model="sconForm.scon_total" disabled maxlength="13" placeholder="暂无合同金额">
              <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交易币种" prop="cust_currency">
            <el-select v-model="sconForm.cust_currency" placeholder="请选择交易币种">
              <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="人民币金额" prop="scon_rmbtotal">
            <el-input v-model="sconForm.scon_rmbtotal" disabled placeholder="暂无人民币金额" maxlength="13">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="运输方式" prop="cust_shipway">
            <el-select v-model="sconForm.cust_shipway" placeholder="请选择运输方式">
              <el-option v-for="item in custShipwayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="保险条款" prop="scon_insure">
            <el-input v-model="sconForm.scon_insure" placeholder="请输入保险条款" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="包装要求" prop="scon_pack">
            <el-input v-model="sconForm.scon_pack" placeholder="请输入包装要求" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8" v-if="isBussinessDept5">
          <el-form-item label="是否导入验货" prop="scon_inspection">
            <el-select v-model="sconForm.scon_inspection" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: 0, label: '否' },
                  { value: 1, label: '是' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="订单类型" prop="scon_category">
            <el-select v-model="sconForm.scon_category" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: '1', label: 'S(非常重要)' },
                  { value: '2', label: 'A(重要)' },
                  { value: '3', label: 'B(一般)' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="系列名" prop="scon_series_name">
            <el-input v-model="sconForm.scon_series_name" placeholder="请输入系列名" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="价格条款" prop="cust_paylaw">
            <el-select v-model="sconForm.cust_paylaw" placeholder="请选择价格条款">
              <el-option v-for="item in custPaylwayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="订单类别" prop="scon_order_series">
            <el-input v-model="sconForm.scon_order_series" placeholder="请输入订单系列" maxlength="50" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="8">
          <el-form-item label="目的地" prop="cust_bourm" v-if="sconForm.scon_type === 2">
            <el-input v-model="sconForm.cust_bourm" placeholder="请输入目的地" maxlength="50" show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 1">
        <el-col :md="24">
          <el-form-item label="货物总称" prop="scon_bulkname">
            <el-input v-model="sconForm.scon_bulkname" type="textarea" rows="3" placeholder="请输入货物总称" maxlength="255" show-word-limit clearable>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 2">
        <el-col :md="24">
          <el-form-item label="交货及验收" prop="deli_and_check">
            <el-input v-model="sconForm.deli_and_check" type="textarea" rows="3" placeholder="请输入" maxlength="255" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 2">
        <el-col :md="24">
          <el-form-item label="其他约定" prop="other_promi">
            <el-input v-model="sconForm.other_promi" type="textarea" rows="3" placeholder="请输入" maxlength="255" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="24">
          <el-form-item label="装运备注" prop="ship_remarks">
            <el-input v-model="sconForm.ship_remarks" type="textarea" rows="3" placeholder="请输入" maxlength="500" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="sconForm.scon_type === 2" class="vg_mt_8">
        <el-col :md="24">
          <el-form-item label="开票资料" prop="scon_inv_gen">
            <el-input v-model="sconForm.scon_inv_gen" clearable maxlength="255" placeholder="请输入" rows="3" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <SconProdList
            :showFlagNumIdCopy="showFlagNumIdCopy"
            ref="SconProdList"
            :sconForm="sconForm"
            :is-show="isShow"
            :prodUnitList="prodUnitList"
            :stffUserList="stffUserList"
            :showFlagNumId="showFlagNumId"
            :addFlagId="addFlagId"
            @confirmProdId="confirmProdId"
            @confirmProdIdCopy="confirmProdIdCopy"
            @addFlagIdChange="addFlagIdChange"
            @sconProdPriceChange="sconProdPriceChange"
            @prodCurrentIndex="changeTransmitIndex"
            @changeMtrbList="index => $refs.sconMtrbList.prodMtrbPeruseChange(index)"
            @changeSyncAll="changeSyncAll"
          ></SconProdList>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="部件明细" name="first" :key="'first'">
              <SconPartList
                ref="SconPartList"
                :suppList="suppList"
                :prodUnitList="prodUnitList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :sconForm="sconForm"
                :is-show="isShow"
                :showFlagNumId="showFlagNumId"
                :transmitIndex="transmitIndex"
                @syncPartMtrbData="index => $refs.SconProdList.prodTotalPrice(index)"
              ></SconPartList>
            </el-tab-pane>
            <el-tab-pane label="材料明细" name="second" :key="'second'">
              <SconMtrbList
                ref="sconMtrbList"
                :suppList="suppList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :transmitIndex="transmitIndex"
                :sconForm="sconForm"
                :is-show="isShow"
                :showFlagNumId="showFlagNumId"
                @syncPartMtrbData="index => $refs.SconProdList.prodTotalPrice(index)"
              ></SconMtrbList>
            </el-tab-pane>
            <el-tab-pane label="猫草信息" name="third" :key="'third'">
              <SconCatnipList
                ref="SconCatnipList"
                :suppList="suppList"
                :prodUnitList="prodUnitList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :transmitIndex="transmitIndex"
                :sconForm="sconForm"
                :is-show="isShow"
                :showFlagNumId="showFlagNumId"
              />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <el-tabs v-model="activeName2" type="border-card">
            <el-tab-pane label="费用明细" name="first" :key="'first'">
              <SconFeesList :sconForm="sconForm" @feetPriceChange="feetPriceChange" ref="sconFeeListRef" />
            </el-tab-pane>
            <el-tab-pane label="条款明细" name="second" :key="'second'">
              <SconClauInfo ref="SconClauInfo" :isShow="isShow" :sconForm="sconForm" @sconClauChange="sconClauChange" @sconClauChange2="sconClauChange2" />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="生成成品采购合同" :visible.sync="dialogVisible" width="70%" class="vd_dialog">
      <generateParts
        ref="generateParts"
        :sconNo="sconForm.scon_no"
        :sconId="sconForm.scon_id"
        :generatePartForm="generatePartForm"
        @generateCancel="generateCancel"
      >
      </generateParts>
      <el-backtop target=".vd_dialog"></el-backtop>
    </el-dialog>
    <el-dialog title="生成采购材料合同" :visible.sync="dialogVisible1" width="70%" class="vd_dialog1">
      <generateMtrb
        :sconNo="sconForm.scon_no"
        :sconId="sconForm.scon_id"
        :generateMtrlForm="generateMtrlForm"
        @generateMtrbEmit="generateMtrbEmit"
      ></generateMtrb>
      <el-backtop target=".vd_dialog1"></el-backtop>
    </el-dialog>
    <el-row class="vg_mt_16">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="客户信息" :visible.sync="dialogCustVisible" width="70%">
      <ImportCust @importCustChange="importCustChange" />
    </el-dialog>
    <el-dialog title="同步Qarma" width="70%" :visible.sync="collectionContractVisible" lazy>
      <el-button class="vg_mb_8" type="info" slot="footer" size="small" @click="importQarma()"> 确定</el-button>
      <search-table
        ref="collectionContract"
        :data="sconPartListForQarmaList"
        :tableRowKey="'contract_id'"
        :need-search="false"
        :need-pagination="false"
        :columns="collectionContractTableProperties"
      />
    </el-dialog>
    <div class="jumpDiv" v-show="jumpDialogVisible">
      <el-card style="height: 100%">
        <span class="el-dialog__title" style="margin-bottom: 16px">数量已修改请去生效的采购合同中变更操作</span>
        <div class="jumpDivClose">
          <el-link :underline="false" icon="el-icon-close" class="vg_cursor" @click="jumpDialogVisible = false"></el-link>
        </div>
        <div style="margin-top: 16px">
          <div v-if="podrMsgList">
            <span>成品采购合同</span>
            <div v-for="(item, index) in podrMsgList">
              <el-link @click="jumpPage({ permId: 127, id: item.podr_id })" class="vg_cursor">
                {{ index + 1 }}.{{ sconForm.scon_no + '-' + item.podr_id }}
              </el-link>
              <span> 我司货号{{ item.prod_no }}数量由</span>
              <span>{{ item.old_scon_part_num }}</span>
              <span>需改为</span>
              <span>{{ item.new_scon_part_num }}</span>
            </div>
          </div>
          <div v-if="modrMsgVos" style="margin-top: 16px">
            <span>材料采购合同</span>
            <div v-for="(item, index) in modrMsgVos">
              <el-link @click="jumpPage({ permId: 128, id: item.modr_id })" class="vg_cursor">
                {{ index + 1 }}.{{ sconForm.scon_no + '-' + item.modr_id }}
              </el-link>
              <span> 材料编号{{ item.mtrb_no }}数量由</span>
              <span>{{ item.old_scon_mtrb_num }}</span>
              <span>需改为</span>
              <span>{{ item.new_scon_mtrb_num }}</span>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import inputUser from '@/views/component/inputUser';
import editHeader from '@/views/component/editHeader';
import SconProdList from './Component/SconProdList.vue'; //成品明细
import SconPartList from './Component/SconPartList.vue'; //部件明细
import SconMtrbList from './Component/SconMtrbList.vue'; //材料明细
import SconFeesList from './Component/SconFeesList.vue'; //费用明细
import SconClauInfo from './Component/SconClauInfo'; //条款明细
import ImportCust from './Component/ImportCust.vue';
import { optnAPI } from '@api/modules/optn';
import { stffAPI } from '@/api/modules/staff';
import { sconAPI } from '@/api/modules/scon';
import { deptAPI } from '@api/modules/department';
import generateParts from './Component/GenerateParts.vue';
import generateMtrb from './Component/GenerateMtrb.vue';
import { BigNumber } from 'bignumber.js';
import { requiredReminder, requiredReminderAndUGrid } from '@assets/js/domUtils';
import { cloneDeep } from 'lodash';
import { getDate, getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { collectionContractTableProperties } from '@/views/SalesManagement/SconManage/scon';
import { getDept, getStff, getSupp } from '@api/public';
import SconCatnipList from '@/views/SalesManagement/SconManage/TabChild/Component/SconCatnipList.vue';
import helper from '@assets/js/helper';
import { docuAPI } from '@api/modules/docu';
import { custAPI } from '@api/modules/cust';
import UrlEncode from '@assets/js/UrlEncode';
import { domeSconRequisitionAPI } from '@api/modules/domeSconRequisition';
import bus from '@/components/common/bus';

export default {
  name: 'SconEditMain',
  computed: {
    docuAPI() {
      return docuAPI;
    },
    helper() {
      return helper;
    },
    BigNumber() {
      return BigNumber;
    }
  },
  components: {
    SconCatnipList,
    SearchTable,
    inputUser,
    editHeader,
    SconProdList,
    SconPartList,
    SconMtrbList,
    SconFeesList,
    SconClauInfo,
    ImportCust,
    generateParts,
    generateMtrb
  },
  data() {
    return {
      custs: [],
      isBussinessDept5: false,
      jumpDialogVisible: false,
      modrMsgVos: [],
      podrMsgList: [],
      stffForm: {},
      isShow: true,
      btn: {},
      selectionsList: [],
      loadingFlag: true,
      sconForm: {
        scon_no: null, //外销合同编号
        scon_shipstat: null, //出运状态
        scon_cust_no: null, //客户订单号
        cust_id: null, //客户id
        cust_abbr: null, //客户简称
        cust_name: null, //客户全称
        cust_bid: null, //最终客户id
        cust_babbr: null, //最总选择客户简称
        scon_cndate: new Date(), //合同日期
        scon_shdate: null, //装运日期
        cust_stff_id: null, //外销经办人
        cust_tradeway: 'General Trade', //贸易方式
        cust_shipway: null, //运输方式
        cust_paylaw: null, //价格条款
        cust_payway: null, //付款方式
        cust_creday: null, //客户账期
        scon_ppdate: null, //预计收款日期
        scon_sport: '上海', //起运港
        cust_dport: null, //目的港
        cust_dcountry: null, //目的国
        scon_prtotal: null, //货款金额
        scon_fetotal: null, //费用金额
        scon_total: null, //合同金额
        cust_currency: null, //币种
        scon_usdrate: null, //美元汇率
        scon_rmbtotal: null, //本币金额 人民币
        scon_bulkname: '', //货物总称
        scon_insure: 'TO BE COVERED BY BUYER', //保险条款
        scon_pack: 'IN CARTONS', //包装要求
        scon_order_series: null, //订单系列
        scon_prod_list: [], //子表 商品明细
        scon_part_list: [], //子表 部件明细
        scon_catnip_list: [], //子表 猫草明细
        scon_mtrb_list: [], //子表 材料明细
        scon_fees_list: [], //子表 费用明细
        cust_dept_id: null, //外销所属部门 111111
        cptt_aid: null, //公司抬头id
        cptt_aname_en: null, //公司英文抬头
        cptt_bid: null, //收款抬头
        cust_shipmark: null, //唛头
        scon_remark: null, //备注
        clau_content: null, //条款内容
        inst_id: null, //货代id 机构
        inst_abbr: null, //机构名称
        inst_ename: null, //货代英文名称 机构
        cptt_bname_en: null, //收款抬头英文
        cptt_bank_name: null, //银行名称
        cptt_bank_account: null, //银行信息,
        financialNotifier: ''
      },
      activeName: 'first',
      activeName2: 'first',
      dialogCustVisible: false,
      importCustType: null,
      stffUserList: [], //经办人
      tradewayList: [], //贸易方式
      custShipwayList: [], //运输方式
      custPaylwayList: [], //价格条款
      custPaywayList: [], //付款方式
      custDportList: [], //港
      portOfShipmentList: [
        { label: 'SHANGHAI', value: 'SHANGHAI' },
        { label: 'NINGBO', value: 'NINGBO' },
        { label: 'QINGDAO', value: 'QINGDAO' },
        { label: 'SHENZHEN', value: 'SHENZHEN' }
      ], //港
      custCountryList: [], //目的国
      custCurrencyList: [], //币种
      deptList: [],
      sconFormRules: {
        cust_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bid: [{ required: true, message: ' ', trigger: 'blur' }],
        // scon_usdrate: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_type: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bourm: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_cndate: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_shdate: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_tradeway: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_shipway: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_paylaw: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_payway: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_sport: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_dept_id: [{ required: true, message: ' ', trigger: 'blur' }],
        ship_remarks: [{ required: false, message: ' ', trigger: 'blur' }]
      },
      showFlagNumId: 0,
      showFlagNumIdCopy: 0,
      transmitIndex: 0,
      addFlagId: -1,
      prodListCopy: [],
      partListCopy: [],
      mtrbListCopy: [],
      catnipListCopy: [], //猫草删除
      generatePartForm: [],
      generateMtrlForm: [],
      dialogVisible: false,
      dialogVisible1: false,
      suppList: [],
      prodUnitList: [],
      tabPaneFlag: false,
      sconTypeList: [
        { id: 1, label: '外贸' },
        { id: 2, label: '内贸' }
      ],
      rateDisable: false,
      calcSconFormTemp: 0,
      recordDialogVisible: false,
      recordList: [],
      collectionContractVisible: false,
      collectionContractTableData: [],
      collectionContractTableProperties: cloneDeep(collectionContractTableProperties),
      sconPartListForQarmaList: [],
      financeList: [],
      cust_shipmark_type: '1',
      isEdit: false
    };
  },
  watch: {
    //获取合同金额
    'sconForm.scon_fetotal': function (newval, oldVal) {
      this.sconForm.scon_total = new BigNumber(Number(this.sconForm.scon_fetotal) + Number(this.sconForm.scon_prtotal)).toFixed(4);
    },
    'sconForm.scon_prtotal': function (newVal, oldVal) {
      this.sconForm.scon_total = new BigNumber(Number(this.sconForm.scon_fetotal) + Number(this.sconForm.scon_prtotal)).toFixed(4);
    },
    //获取人民币总金额
    'sconForm.scon_total': function (newVal, oldVal) {
      this.sconForm.scon_rmbtotal = new BigNumber(this.sconForm.scon_usdrate * this.sconForm.scon_total).toFixed(4);
    },
    'sconForm.scon_usdrate': function (newVal, oldVal) {
      this.sconForm.scon_rmbtotal = new BigNumber(this.sconForm.scon_usdrate * this.sconForm.scon_total).toFixed(4);
    },
    'sconForm.cust_currency'(newVal, oldVal) {
      if (newVal === 'RMB') {
        this.sconForm.scon_usdrate = 1;
        this.rateDisable = true;
      } else if (newVal === 'USD') {
        this.rateDisable = false;
      } else {
        this.sconForm.scon_usdrate = null;
      }
    },
    //新增和删除时计算成品价格
    'sconForm.scon_prod_list'() {
      let sum = 0;
      this.sconForm.scon_prod_list.forEach(item => {
        sum += item.prod_total_price - 0;
      });
      this.sconForm.scon_prtotal = new BigNumber(sum).toFixed(4);
    }
  },
  async created() {
    await this.initData();
    this.isBussinessDept5 = [...(await getDept('业务五部', 'ids')), 1].includes(this.$cookies.get('userInfo').dept_id);
  },
  mounted() {
    bus.$on('updateScon', data => {
      console.log(222, data);
      if (data.form_id === this.sconForm.scon_id) {
        this.$notify({
          duration: 0,
          title: '销售合同更新提醒',
          customClass: 'customClass',
          message: this.$createElement(
            'div',
            {
              style: {
                display: 'flex',
                width: '272px'
              }
            },
            [this.$createElement('span', null, data.appr_inst_info)]
          )
        });
        this.isEdit = true;
      }
    });
  },
  methods: {
    getDate,
    getDateNoTime,
    //启用编辑
    openEdit() {
      if (this.isEdit) {
        //重新获取表单数据
        this.getSconInfo();
      }
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      });
    },
    async initData() {
      this.loadingFlag = true;
      this.getCusts();
      this.getSconInfo();
      this.getTradeway();
      this.getCustShipway();
      this.getCustPayway();
      this.getCustPaylway();
      this.getCustDport();
      this.getContry();
      this.getCustCurrency();
      this.getDept();
      this.getProdRankList();
      this.getSupp();
      this.financeList = await getStff('财务', 'valueList');
      // this.custList = await getCustomerInfo({ status: 2, type: 'name' });
    },
    getCusts() {
      let { dept_name, dept_id } = this.$cookies.get('userInfo');
      let currentDeptId = ['业务一部', '业务二部', '业务五部'].includes(dept_name) ? dept_id : null;
      get(custAPI.getAllCustsV3, { dept_id: currentDeptId, page_no: null })
        .then(res => {
          if (res.data.code === 0) {
            this.custs = res.data.data.list;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    // 获取供应商
    async getSupp() {
      let [valueListObj, valueListArr] = await getSupp('valueListAndDefault');
      await (this.collectionContractTableProperties.find(({ label }) => label === '实际供应商').subItem.options = valueListArr);
      await (this.collectionContractTableProperties.find(({ label }) => label === '指定供应商').subItem.options = valueListArr);
      await this.$refs.SconPartList.changeProperties(valueListObj);
      await this.$refs.sconMtrbList.changeProperties(valueListObj);
      await this.$refs.SconCatnipList.changeProperties(valueListObj);
    },
    custDeptChange(val) {
      this.sconForm.cust_stff_id = null;
      this.getStffUser(val);
      //更改销售所属部门后清空成品表对应外销
      this.sconForm.scon_prod_list.forEach(item => {
        item.scon_stff_id = null;
        item.cust_stff_name = '';
      });
    },
    //获取经办人
    getStffUser(val) {
      get(stffAPI.getAllStffsV1, { dept_id: val })
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = Array.from(res.data.data, ({ stff_id, stff_name }) => {
              return { stff_id: stff_id, stff_name: stff_name };
            });
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取贸易方式
    getTradeway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10016 }).then(({ data }) => {
        this.tradewayList = data.form.optn_cntt_list;
      });
    },
    // 获取单位
    getProdRankList() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10008 }).then(({ data }) => {
        this.prodUnitList = data.form.optn_cntt_list;
      });
    },
    // 获取运输方式
    getCustShipway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10017 }).then(({ data }) => {
        this.custShipwayList = data.form.optn_cntt_list;
      });
    },
    // 获取价格条款
    getCustPaylway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10014 }).then(({ data }) => {
        this.custPaylwayList = data.form.optn_cntt_list;
      });
    },
    //付款方式联动方法
    updatePpdate(val) {
      this.sconForm.cust_creday = Number(this.custPaywayList.find(({ param1 }) => val === param1).param2);
      if (this.sconForm.scon_shdate) {
        this.sconForm.scon_ppdate = this.sconForm.scon_shdate + this.sconForm.cust_creday * 60 * 60 * 24 * 1000;
      }
    },
    // 获取付款方式
    getCustPayway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10013 }).then(({ data }) => {
        this.custPaywayList = data.form.optn_cntt_list;
      });
    },
    // 获取港
    getCustDport() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10018 }).then(({ data }) => {
        this.custDportList = data.form.optn_cntt_list;
      });
    },
    // 获取国家
    getContry() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10003 }).then(({ data }) => {
        this.custCountryList = data.form.optn_cntt_list;
      });
    },
    // 获取币种
    getCustCurrency() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10015 }).then(({ data }) => {
        this.custCurrencyList = data.form.optn_cntt_list;
      });
    },
    // 材料和部件部分
    // 获取公共prod_id
    confirmProdId(val) {
      this.showFlagNumId = val;
      this.showFlagNumIdCopy = 0;
      // this.changeSconPartListByID(val, false);
      // this.changeSconMtrbListByID(val, false);
    },
    confirmProdIdCopy(val) {
      this.showFlagNumIdCopy = val;
      this.showFlagNumId = 0;
      // this.changeSconPartListByID(val, true);
      // this.changeSconMtrbListByID(val, true);
    },
    changeTransmitIndex(val) {
      this.transmitIndex = val;
    },
    changeSyncAll() {
      this.$refs.SconPartList.refresh();
      this.$refs.sconMtrbList.refresh();
      this.$refs.SconCatnipList.syncCatnip();
    },
    addFlagIdChange(val) {
      this.addFlagId = val;
    },
    //提交表单
    submit(formName) {
      if (this.isEdit) {
        return this.$message.error('此单据已被修改，请取消保存后刷新！');
      }
      let temp = [];
      //判断所有成品下的部件是否都有主产品
      this.sconForm.scon_prod_list.forEach(item => {
        let find = this.sconForm.scon_part_list.filter(x => x.scon_prod_id === item.scon_prod_id);
        if (find.length > 0) {
          let flag = find.some(item => item.prod_mainsup === 1);
          if (!flag) {
            temp.push(item.prod_no);
          }
        }
      });
      if (temp.length > 0) {
        return this.$message.info('成品我司货号：' + temp.toString() + '没有主产品,不能保存');
      }
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable1 = await this.$refs.SconProdList.$refs.SconProdList.fullValidateMsg();
          let dynamicUTable2 = await this.$refs.SconPartList.$refs.SconPartList.fullValidateMsg();
          let dynamicUTable3 = await this.$refs.sconMtrbList.$refs.SconMtrbList.fullValidateMsg();
          this.$refs[formName].validate((valid, object) => {
            if (valid && !dynamicUTable1 && !dynamicUTable2 && !dynamicUTable3) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable1) tempStr += `成品子表-${dynamicUTable1}`;
              if (dynamicUTable2) tempStr += `部件子表-${dynamicUTable2}`;
              if (dynamicUTable3) tempStr += `材料子表-${dynamicUTable3}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      let sconForm = JSON.parse(JSON.stringify(this.sconForm));
      sconForm.scon_cndate = this.helper.getNewDate(sconForm.scon_cndate); //合同日期
      sconForm.scon_shdate = this.helper.getNewDate(sconForm.scon_shdate); //装运日期
      sconForm.scon_ppdate = sconForm.scon_ppdate ? this.helper.getNewDate(sconForm.scon_ppdate) : 0; //预计收款日期

      // 成品、材料、部件
      // 成品
      this.prodListCopy = this.prodListCopy.filter(item => sconForm.scon_prod_list.every(item1 => item.scon_prod_id !== item1.scon_prod_id));
      for (let i in this.prodListCopy) {
        this.prodListCopy[i].destroy_flag = 1;
      }
      sconForm.scon_prod_list = sconForm.scon_prod_list.concat(this.prodListCopy);
      // 部件
      this.partListCopy = this.partListCopy.filter(item => sconForm.scon_part_list.every(item1 => item.scon_part_id !== item1.scon_part_id));
      for (let i in this.partListCopy) {
        this.partListCopy[i].destroy_flag = 1;
      }
      // sconForm.scon_part_list = sconForm.scon_part_list.concat(Array.from(this.$refs.SconPartList.subTableMap.values()));
      sconForm.scon_part_list = sconForm.scon_part_list.concat(this.partListCopy);
      // 材料
      this.mtrbListCopy = this.mtrbListCopy.filter(item => sconForm.scon_mtrb_list.every(item1 => item.scon_mtrb_id !== item1.scon_mtrb_id));
      for (let i in this.mtrbListCopy) {
        this.mtrbListCopy[i].destroy_flag = 1;
      }
      sconForm.scon_mtrb_list = sconForm.scon_mtrb_list.concat(this.mtrbListCopy);
      // 猫草
      this.catnipListCopy = this.catnipListCopy.filter(item => sconForm.scon_catnip_list.every(item1 => item.scon_catnip_id !== item1.scon_catnip_id));
      for (let i in this.catnipListCopy) {
        this.catnipListCopy[i].destroy_flag = 1;
      }
      sconForm.scon_catnip_list = sconForm.scon_catnip_list.concat(this.catnipListCopy);

      for (let i = 0; i < sconForm.scon_prod_list.length; i++) {
        let temp = 0;
        for (let j = 0; j < sconForm.scon_part_list.length; j++) {
          if (sconForm.scon_prod_list[i].scon_part_id === sconForm.scon_part_list[j].scon_part_id) {
            if (sconForm.scon_part_list[j].prod_mainsup === '1') {
              temp++;
            }
          }
        }
        if (temp > 1) {
          this.$message.warning('每一个成品只能包含一个为主厂的部件');
          break;
        }
        //计算成品位置
        sconForm.scon_prod_list[i].prod_sort = i + 1;
      }
      sconForm.scon_fees_list.push(...this.$refs.sconFeeListRef.delList);
      // sconForm.scon_catnip_list.push(...this.$refs.SconCatnipList.delList);
      postNoCatch(sconAPI.editScon, sconForm).then(({ data }) => {
        this.podrMsgList = [];
        this.modrMsgVos = [];
        let { podrMsgList, modrMsgVos } = data;
        if (podrMsgList.length > 0 || modrMsgVos.length > 0) {
          this.jumpDialogVisible = true;
          this.podrMsgList = podrMsgList;
          this.modrMsgVos = modrMsgVos;
        }
        this.$message.success('保存成功!');
        this.isEdit = false;
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
      });
    },
    //获取表单信息
    getSconInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(sconAPI.getSconById, { scon_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.sconPartListForQarmaList = res.data.data.sconPartListForQarmaList;
            this.sconForm = res.data.data.form;
            this.$emit('setSconForm', this.sconForm);
            this.sconForm.scon_fees_list.forEach(item => {
              item.scon_fees_usd = new BigNumber(item.scon_fees_usd).toFixed(4);
            });
            this.sconForm.scon_cndate = this.sconForm.scon_cndate * 1000; //合同日期
            this.sconForm.scon_shdate = this.sconForm.scon_shdate * 1000; //装运日期
            this.sconForm.scon_usdrate = new BigNumber(this.sconForm.scon_usdrate).toFixed(6);
            this.prodListCopy = JSON.parse(JSON.stringify(this.sconForm.scon_prod_list));
            this.partListCopy = JSON.parse(JSON.stringify(this.sconForm.scon_part_list));
            this.catnipListCopy = JSON.parse(JSON.stringify(this.sconForm.scon_catnip_list));
            this.mtrbListCopy = JSON.parse(JSON.stringify(this.sconForm.scon_mtrb_list));
            this.sconForm.scon_ppdate = this.sconForm.scon_ppdate === 0 ? null : this.sconForm.scon_ppdate * 1000; //预计收款日期
            this.calcSconFormTemp = cloneDeep(this.sconForm.scon_ppdate);
            this.sconForm.scon_prtotal = new BigNumber(this.sconForm.scon_prtotal).toFixed(4);
            this.sconForm.scon_fetotal = new BigNumber(this.sconForm.scon_fetotal).toFixed(4);
            this.isShowInputer = true;
            this.stffForm.stff_name = this.sconForm.stff_name;
            this.stffForm.dept_name = this.sconForm.dept_name;
            this.stffForm.dept_team_name = this.sconForm.dept_team_name;
            this.stffForm.user_id = this.sconForm.user_id;
            this.stffForm.dept_id = this.sconForm.dept_id;
            this.stffForm.stff_id = this.sconForm.stff_id;
            this.stffForm.scon_pack = this.sconForm.scon_pack ? this.sconForm.scon_pack : '装箱';
            this.btn = res.data.data.btn;
            if (this.sconForm.scon_type === 0) {
              this.sconForm.scon_type = null;
            }
            //计算子表数据
            this.calcForm();
            if (val === 1) {
              this.loadingFlag = true;
              this.generatePart();
            } else if (val === 2) {
              this.loadingFlag = true;
              this.generateMtrb();
            }
            setTimeout(() => {
              this.loadingFlag = false;
              this.getStffUser(this.sconForm.cust_dept_id);

              // if (this.sconForm.cptt_aid !== 0) {
              //   this.getCpttBankByCptt();
              // }
              setTimeout(() => {
                if (this.sconForm.scon_prod_list.length > 0) {
                  this.initPartMtrbListData();
                }
              }, 100);
            }, 500);
            this.isEdit = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.log(err));
    },
    initPartMtrbListData() {
      this.$refs.SconProdList.getIndex = 0;
      this.showFlagNumIdCopy = 0;
      this.showFlagNumId = 0;
      if (this.sconForm.scon_prod_list[0].scon_prod_id > 0) {
        this.showFlagNumIdCopy = this.sconForm.scon_prod_list[0].scon_prod_id;
      } else {
        this.showFlagNumId = this.sconForm.scon_prod_list[0].prod_id;
      }
    },
    // 计算内容
    calcForm() {
      for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
        // this.sconForm.scon_prod_list[i].prod_total_price = this.helper.haveFour(
        //   new BigNumber(this.sconForm.scon_prod_list[i].prod_usd).times(this.sconForm.scon_prod_list[i].prod_num).toNumber()
        // );
        // this.sconForm.scon_prod_list[i].prod_box_num = Math.ceil(
        //   Number(this.sconForm.scon_prod_list[i].prod_num) / Number(this.sconForm.scon_prod_list[i].prod_qpc)
        // );
        //计算总体积毛净重
        let calcNumO = new BigNumber(this.sconForm.scon_prod_list[i].prod_box_num);
        calcNumO = calcNumO.times(this.sconForm.scon_prod_list[i].prod_gweight).toNumber();
        this.sconForm.scon_prod_list[i].total_gross_weight = this.helper.reservedDigits(calcNumO);
        let calcNumT = new BigNumber(this.sconForm.scon_prod_list[i].prod_box_num);
        calcNumT = calcNumT.times(this.sconForm.scon_prod_list[i].prod_nweight).toNumber();
        this.sconForm.scon_prod_list[i].total_net_weight = this.helper.reservedDigits(calcNumT);
        let calcNumF = new BigNumber(this.sconForm.scon_prod_list[i].prod_box_num);
        calcNumF = calcNumF.times(this.sconForm.scon_prod_list[i].prod_vol).toNumber();
        this.sconForm.scon_prod_list[i].total_volume = calcNumF.toFixed(3);
        // this.sconForm.scon_prod_list[i].prod_usd = this.helper.haveFour(this.sconForm.scon_prod_list[i].prod_usd);
        // let prodId = this.sconForm.scon_prod_list[i].scon_prod_id;
        for (let j = 0; j < this.sconForm.scon_part_list.length; j++) {
          // this.sconForm.scon_part_list[j].prod_price = this.helper.haveFour(this.sconForm.scon_part_list[j].prod_price);
          // if (prodId === this.sconForm.scon_part_list[j].scon_prod_id) {
          // this.sconForm.scon_part_list[j].prod_num = this.sconForm.scon_prod_list[i].prod_num;
          // this.sconForm.scon_part_list[j].scon_part_num = this.helper.haveFour(Number(this.sconForm.scon_part_list[j].prod_use)*100000*Number(this.sconForm.scon_part_list[j].prod_num)/100000)
          //计算金额 = 实际数量 * 单价
          this.sconForm.scon_part_list[j].total_price = new BigNumber(
            Number(this.sconForm.scon_part_list[j].scon_part_num_reality) * Number(this.sconForm.scon_part_list[j].prod_price)
          ).toFixed(4);
          // }
          // if (this.sconForm.scon_part_list[j].supp_id === 0) {
          //   this.sconForm.scon_part_list[j].supp_id = null;
          // }
          // this.sconForm.scon_part_list[j].surplus_price =
          //   (Number(this.sconForm.scon_part_list[j].scon_part_num) * 100000 -
          //     Number(this.sconForm.scon_part_list[j].scon_part_applied) * 100000 -
          //     Number(this.sconForm.scon_part_list[j].scon_part_apply) * 100000) /
          //   100000;
        }
        for (let j = 0; j < this.sconForm.scon_mtrb_list.length; j++) {
          // if (prodId === this.sconForm.scon_mtrb_list[j].scon_prod_id) {
          //   this.sconForm.scon_mtrb_list[j].prod_num = this.sconForm.scon_prod_list[i].prod_num;
          // this.sconForm.scon_mtrb_list[j].prod_mtrb_peruse = this.helper.reservedDigits(Number(this.sconForm.scon_mtrb_list[j].prod_mtrb_use)*100000/Number(this.sconForm.scon_mtrb_list[j].prod_mtrb_per)/100000)
          //计算金额
          this.sconForm.scon_mtrb_list[j].mtrb_total_price = new BigNumber(
            Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_num) * Number(this.sconForm.scon_mtrb_list[j].prod_mtrb_price)
          ).toFixed(4);
          // }
          // this.sconForm.scon_mtrb_list[j].scon_mtrb_num = this.helper.reservedDigits(this.sconForm.scon_mtrb_list[j].scon_mtrb_num);
          // this.sconForm.scon_mtrb_list[j].scon_mtrb_applied = this.helper.reservedDigits(this.sconForm.scon_mtrb_list[j].scon_mtrb_applied);
          // this.sconForm.scon_mtrb_list[j].scon_mtrb_apply = this.helper.reservedDigits(this.sconForm.scon_mtrb_list[j].scon_mtrb_apply);
          // this.sconForm.scon_mtrb_list[j].prod_mtrb_price = this.helper.haveFour(this.sconForm.scon_mtrb_list[j].prod_mtrb_price);
          // this.sconForm.scon_mtrb_list[j].surplus_price = this.helper.reservedDigits(
          //   (Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_num) * 100000 -
          //     Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_applied) * 100000 -
          //     Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_apply) * 100000) /
          //     100000
          // );
        }
      }
      this.tabPaneFlag = true;
    },
    //选择客户
    clickSelectCust(id, type) {
      let row = cloneDeep(this.custs.find(x => x.cust_id === id));
      this.importCustType = type;
      if (row) {
        this.importCustChange(row);
      }
      // this.importCustType = val;
      // this.dialogCustVisible = true;
    },
    //装运日期联动更改预计收款日期
    chageShdate(val) {
      //如果装运日期 = null 则预计收款日期 = null
      if (!val) {
        this.sconForm.scon_ppdate = null;
        return;
      }
      //否则计算时间
      let cust_creday = 0;
      if (this.sconForm.scon_shdate) {
        cust_creday = this.sconForm.cust_creday;
      }
      this.sconForm.scon_ppdate = val + cust_creday * 86400000;
    },
    //双击选中 客户
    importCustChange(row) {
      if (this.importCustType === 2) {
        this.getStffUser(row.cust_dept_id);
        this.sconForm.cust_dept_id = row.cust_dept_id; //外销所属部门
        this.sconForm.cust_stff_id = row.cust_stff_id; //外销经办人
        this.sconForm.cust_babbr = row.cust_abbr; //最终选择客户 简称
        this.sconForm.cust_bid = row.cust_id; //最终选择客户 id
        this.sconForm.cust_dport = row.cust_dport; //目的港
        this.sconForm.cust_payway = row.cust_payway; //付款方式
      } else {
        this.sconForm.cust_dept_id = row.cust_dept_id; //外销所属部门
        this.sconForm.cust_abbr = row.cust_abbr; //客户简称
        this.sconForm.cust_id = row.cust_id; //客户id
        this.sconForm.cust_babbr = row.cust_abbr; //最终选择客户 简称
        this.sconForm.cust_bid = row.cust_id; //最终选择客户 id
        this.sconForm.cust_tradeway = row.cust_tradeway; //贸易方式
        this.sconForm.cust_shipway = row.cust_shipway; //运输方式
        this.sconForm.cust_paylaw = row.cust_paylaw; //价格条款
        this.sconForm.cust_payway = row.cust_payway; //付款方式
        this.sconForm.cust_creday = row.cust_creday; //客户账期
        this.sconForm.cust_dport = row.cust_dport; //目的港
        this.sconForm.cust_dcountry = row.cust_country_eng; //目的国
        this.sconForm.cust_currency = row.cust_currency; //币种
        this.sconForm.cptt_aname_en = row.cptt_aname; //公司抬头
        this.$refs.SconClauInfo.changeCpttAnameEn(row.cptt_aname);
        // this.sconForm.cptt_aid = row.cptt_aid; //公司抬头id
        // this.sconForm.cptt_bname_en = row.cptt_bname; //公司抬头
        // this.sconForm.cptt_bid = row.cptt_bid; // 收款抬头id
        this.sconForm.cust_shipmark = row.cust_shipmark; //唛头
        this.sconForm.inst_id = row.inst_id;
        this.sconForm.inst_abbr = row.inst_abbr; //货代简称
        this.sconForm.inst_ename = row.inst_ename; //货代英文名
        if (row.cust_stff_id) {
          this.sconForm.cust_stff_id = row.cust_stff_id;
        } else {
          this.sconForm.cust_stff_id = null;
        }
        this.getStffUser(this.sconForm.cust_dept_id);
        // if (row.cptt_aid !== 0) {
        //   this.getCpttBankByCptt();
        // }
      }
      this.dialogCustVisible = false;
    },
    //装运日期选择
    shdateChange(evt) {
      if (this.sconForm.cust_creday) {
        this.sconForm.scon_ppdate = evt.getTime() + this.sconForm.cust_creday * 86400000;
      } else {
        this.sconForm.scon_ppdate = evt;
      }
    },
    // 客户账期选择联动影响收款日期
    shdateChange2() {
      let evt = null;
      let type = Object.prototype.toString.call(this.sconForm.scon_shdate);
      if (type != '[object Date]') {
        evt = new Date(this.sconForm.scon_shdate);
      } else {
        evt = this.sconForm.scon_shdate;
      }
      this.shdateChange(evt);
    },
    //条款内容变化
    sconClauChange(row) {
      this.sconForm.clau_content = row.clau_content;
    },
    //机构信息变化
    sconClauChange2(row) {
      this.sconForm.inst_id = row.inst_id;
      this.sconForm.inst_ename = row.inst_ename;
      this.sconForm.inst_abbr = row.inst_abbr;
    },
    //费用金额 变化
    feetPriceChange(val) {
      this.sconForm.scon_fetotal = new BigNumber(val).toFixed(4);
    },
    buttonRefresh() {
      this.initData();
    },
    sconProdPriceChange() {
      let sum = 0;
      this.sconForm.scon_prod_list.forEach(item => {
        sum += item.prod_total_price - 0;
      });
      this.sconForm.scon_prtotal = new BigNumber(sum).toFixed(4);
    },
    // 生成成品部件合同
    async generatePart() {
      // let temp = JSON.parse(JSON.stringify(this.sconForm.scon_part_list))
      // let temp1 = [];
      // for (let i=0;i<temp.length;i++) {
      //   temp[i].prod_mainsup = temp[i].prod_mainsup + ''
      //   let flag = false;
      //   let ind = 0;
      //   for(let j=0;j<temp1.length;j++){
      //     if(temp[i].supp_id === temp1[j].supp_id){
      //       flag = true;
      //       ind = j
      //     }
      //   }
      //   if(Number(temp[i].scon_part_apply) !== 0){
      //     if(flag){
      //       temp1.splice(ind,0,temp[i])
      //     }else{
      //       temp1.push(temp[i])
      //     }
      //   }
      // }
      // this.generatePartForm = temp1;

      await this.confirmPart();
      this.$refs.generateParts.getScons();
    },
    confirmPart() {
      this.loadingFlag = false;
      this.dialogVisible = true;
    },
    // 取消生成成品部件合同
    generateCancel() {
      this.dialogVisible = false;
      this.initData();
    },
    // 生成材料合同
    generateMtrb() {
      let temp = JSON.parse(JSON.stringify(this.sconForm.scon_mtrb_list.filter(x => x.scon_mtrb_apply !== 0)));
      let temp1 = [];
      temp.forEach(item => {
        this.sconForm.scon_prod_list.forEach(item2 => {
          if (item.prod_id === item2.prod_id) {
            item.prod_no = item2.prod_no;
            item.prod_poststfix = item2.prod_poststfix;
            item.prod_cust_no = item2.prod_cust_no;
          }
        });
      });
      let isMessage = false;
      for (let i = 0; i < temp.length; i++) {
        let flag = false;
        let ind = 0;
        for (let j = 0; j < temp1.length; j++) {
          if (temp[i].supp_id === temp1[j].supp_id) {
            flag = true;
            ind = j;
          }
        }
        if (Number(temp[i].scon_mtrb_apply) === 0) {
          isMessage = true;
        } else {
          if (flag) {
            temp1.splice(ind, 0, temp[i]);
          } else {
            temp1.push(temp[i]);
          }
        }
      }
      if (isMessage) this.$message.warning('请填写成品数量');
      this.generateMtrlForm = temp1;
      this.dialogVisible1 = true;
    },
    generateMtrbEmit() {
      this.dialogVisible1 = false;
      this.initData();
    },

    // 打印
    clickPrint(val) {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadingFlag = true;
          getNoCatch(val === 5 ? sconAPI.exportInvPdf : sconAPI.exportSconPdfById, {
            type: 2,
            scon_type: this.sconForm.scon_type,
            scon_id: this.sconForm.scon_id,
            versions: val ? val : 1,
            cust_shipmark_type: this.cust_shipmark_type === '1'
          }).then(({ data }) => {
            const urlPrefix = process.env.NODE_ENV === 'production' ? '/erpfile' : '/file';
            window.open(urlPrefix + data.pdf_url + '.pdf');
            this.loadingFlag = false;
          });
        })
        .catch(() => {});
    },
    clickExportExcel(val) {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch(val === 5 ? sconAPI.exportInvPdf : sconAPI.exportSconPdfById, {
            type: 1,
            scon_type: this.sconForm.scon_type,
            scon_id: this.sconForm.scon_id,
            versions: val ? val : 1,
            cust_shipmark_type: this.cust_shipmark_type === '1'
          }).then(({ data }) => {
            this.helper.downloadItemExcel(data.pdf_url, `${data.fileName}.xls`);
          });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExport(val) {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch(val === 5 ? sconAPI.exportInvPdf : sconAPI.exportSconPdfById, {
            type: 2,
            scon_type: this.sconForm.scon_type,
            scon_id: this.sconForm.scon_id,
            versions: val ? val : 1,
            cust_shipmark_type: this.cust_shipmark_type === '1'
          }).then(({ data }) => {
            this.helper.downloadItem(data.pdf_url, `${data.fileName}.pdf`);
          });
        })
        .catch(() => {});
    },
    jumpPage(val) {
      this.jump(`/${val.permId === 128 ? 'm' : 'p'}odr_edit`, {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: val.permId,
            form_id: val.id
          })
        )
      });
    },
    changeCurrency(type) {
      this.sconForm.cust_currency = type;
      this.$refs.SconProdList.changePriceName(type);
    },
    //生成销售合同变跟单按钮
    generateSconChangeForm() {
      this.$confirm('确认是否生成销售合同变更单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch(sconAPI.addSconOrder, { scon_id: this.sconForm.scon_id }).then(({ data }) => {
            this.$message.success('生成成功!');
            this.jump('/sconorder_edit', {
              key: this.$UrlEncode.encode(
                JSON.stringify({
                  perm_id: 181,
                  form_id: data.form_id,
                  scon_type: this.sconForm.scon_type
                })
              )
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    importQarma() {
      sconAPI.getSconProdToQarmaByProdid(this.sconPartListForQarmaList).then(({ data }) => {
        this.$message.success('导入成功!');
      });
    },
    getRecordData() {
      getNoCatch(sconAPI.getSconChangehistory, { scon_id: this.sconForm.scon_id }).then(({ data }) => {
        this.recordList = data;
      });
    },
    //检查客户订单号是否重复
    changeCustNoIsRepeat(val) {
      if (val.target.value) {
        get(sconAPI.changeCustNoIsRepeat, { scon_cust_no: val.target.value, scon_id: this.sconForm.scon_id })
          .then(res => {
            if (res.data.code === 0) {
              let flag = res.data.data.isFlag;
              if (flag) {
                this.$message.error('客户订单号重复！');
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    uploadInvoicingDetails(file) {
      let fileName = file.name.substr(0, file.name.lastIndexOf('.'));
      if (fileName.length > 500) {
        this.$message.warning('文件名字过长! 长度不可大于500!');
        return false;
      }
      if (file.name === ' ' || file.name === null) {
        this.$message.warning('文件名字不能为空');
        return false;
      }
      if (file.size === 0) {
        this.$message.warning('不能上传空文件');
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 200;
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 200MB!');
      }
      return isLt5M;
    },
    notifyFinance() {
      if (!this.sconForm.financialNotifier) return this.$message.warning('请选择财务通知人');
      sconAPI.callFinance({ stff_id: this.sconForm.financialNotifier, scon_id: this.sconForm.scon_id }).then(({ data }) => {
        this.$message.success('操作成功!');
      });
    },
    changeCustStff(val) {
      this.sconForm.scon_prod_list.forEach(item => {
        item.scon_stff_id = val;
      });
    },
    //新增内贸销售开票通知
    addDomeSconRequisition() {
      domeSconRequisitionAPI.add_domeScon({ scon_id: this.sconForm.scon_id }).then(({ data }) => {
        this.$message.success('生成成功!');
        this.jump('/domeScon_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 208,
              form_id: data.form_id
            })
          )
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_edhea {
  position: relative;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.jumpDiv {
  z-index: 2001;
  position: absolute;
  top: 5%;
  left: 30%;
  width: 500px;
}

.jumpDivClose {
  position: absolute;
  top: 10px;
  right: 10px;
}

.upload-demo ::v-deep .el-upload--text {
  width: auto;
  height: auto;
  text-align: left;
  border: none;
  border-radius: 0;
}

.el-upload__tip {
  margin-bottom: 16px;
}
</style>
